import React, { useRef, useEffect } from 'react'
import Axios from 'axios';
import queryString from 'query-string'
import { navigate } from "gatsby"

import { useLocation } from "@reach/router"

import InputBase from '@mui/material/InputBase';
import { styled, alpha } from "@mui/material/styles"

import Alert from '@mui/material/Alert';
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import { initAxiosInterceptors } from 'utils/axiosConfig'
import { useDialog } from 'context/DialogContext'
import { useLoading } from 'context/LoadingContext'

import TemplateBlank from 'LandingPageMaterial/Layout/TemplateBlank'
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js";
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js";
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button";

import BudgetTitle from 'Portal/Views/Budget/BudgetTitle'

import { useForm, Controller } from "react-hook-form";
import cotizarValidaStyles from '../styles/cotizarValidaStyles'


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '10px',
    margin: '0 7px',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));







export default function CotizarValida(props) {
  
  const classItem = {
    ...cotizarValidaStyles.styleItem,
  }
  const classAlert = {
    ...cotizarValidaStyles.alertStyle,
  }
  const dialog = useDialog()
  const loading = useLoading()
  const location = useLocation()
  const { handleSubmit, errors, control } = useForm();
  const inputNumbers = 4;
  const params_url = queryString.parse(props.location.search)

  useEffect(() =>{
    initAxiosInterceptors(dialog,loading)
  },[])

  const inputRef = useRef([]);
  inputRef.current = new Array(inputNumbers);

  function AlertEl(props) {
    return <Alert elevation={6} variant="filled" {...props} />
  }

  function onFinish(hash) {
    navigate(`/cotizar?id=${hash}`, { replace: true })
  }

  const getCode = () => {
    let data = '';
    const dataReturn = inputRef.current.map((el) => {
      data = `${data}${el.value}`
    })
    console.log(dataReturn)
    return data;
  }

  async function onSubmit(data, e) {
    const code = {
      code_validation: `${getCode()}`
    }
    try {
      const params = {
        p_budget_id: params_url.id,
        p_json_info: JSON.stringify(code)
      }
      const response = await Axios.post('/dbo/budgets/validate_sms', params)
      const paramsSend = { p_budget_id: params_url.id, p_location: location.origin }
      Axios.post('/dbo/budgets/send_budget', paramsSend)
      onFinish(response.data.p_hash)
    } catch (error) {
      console.error(error)
    }
  }

  async function reSendSms() {
    try {
      const params = { p_budget_id: params_url.id }
      await Axios.post('/dbo/budgets/re_send_sms_code', params)
      dialog({
        variant: "info",
        catchOnCancel: false,
        title: "Exito",
        description: "Se ha enviado un nuevo codigo SMS"
      })
    } catch (error) {
      console.error(error)
    }
  }

  const focusNext = (i) => {
    return inputRef.current[i + 1] !== undefined && inputRef.current[i + 1].focus()
  }

  return (
    <TemplateBlank title="Validar código de Cotización">
      <BudgetTitle title="Validación de datos" />
      <GridContainer>
      <GridItem xs={12} sm={12} md={3} ></GridItem>
        <GridItem xs={12} sm={12} md={6} >
          <GridItem xs={12} sm={12} md={12} sx={{my:'30px',mx:0}}>
            <AlertEl severity="success">Para continuar con su cotización le hemos enviado el código de verificación via SMS a su teléfono </AlertEl>
          </GridItem>
          <form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer sx={{my:'30px',mx:0}} spacing={2} justifyContent="center" alignItems="center">
              <GridItem xs={12} sm={12} md={12} className={classItem}>
                {[...Array(inputNumbers)].map((el, i) => {
                  return (
                    <Controller
                      control={control}
                      name={`numero${i + 1}`}
                      inputProps={{ maxLength: 1 }}
                      render={({field}) => (
                        <BootstrapInput
                          {...field}  
                          inputRef={el => inputRef.current[i] = el}
                          onChange={(e) => {
                            focusNext(i);
                            return e.target.value;
                          }}

                        />
                      )}
                    />
                  )
                })}
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <Button color="primary" type="submit"><Icon>send</Icon> Enviar</Button>
            </GridContainer>
          </form>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <Alert severity='warning' className={classAlert}>
              <br></br>¿No le ha llegado el SMS?<br></br>Solicite el envío nuevamente presionando aquí<br></br>
              <IconButton color="secondary" aria-label="add an alarm" onClick={reSendSms}><TouchAppIcon /></IconButton>
            </Alert>
          </GridItem>
        </GridItem>
      </GridContainer>
    </TemplateBlank>
  )
}
